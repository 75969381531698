module controllers {
    export module master {

        interface IVesselUpdateCtrlScope extends ng.IScope {
        }

        interface IVesselUpdateParams extends ng.ui.IStateParamsService {
             vesId: number;
        }

        export class vesselUpdateCtrl {

            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                "$uibModal",
                "vesselService",
                "$state",
                "$stateParams",
                "$transitions",
                'countryService',
                '$anchorScroll',
            ];

         

            ctrlName: string = "vessel update";
            vesId: number;

            vesselMaster: interfaces.master.VesselMaster;
            breadCrumbDesc: string;
            public isLoadingDetail: boolean = false;

            getShippingLinesSMDList: Array<interfaces.applicationcore.IDropdownModel>;
            apiVesselCustomsOperatorCode: uiGrid.IGridApi;

            constructor(private $scope: IVesselUpdateCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private vesselService: interfaces.master.IVesselService,
                private $state: ng.ui.IStateService,
                private $stateParams: IVesselUpdateParams,
                private $transitions: ng.ui.core.ITransition,
                public countryService: interfaces.applicationcore.ICountryService,
                private $anchorScroll: ng.IAnchorScrollService,
            ) {
                this.vesId = $stateParams.vesId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadVessel(this.vesId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ves.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });


            }

            loadVessel(vesselId: number){
                return this.vesselService.getVessel(vesselId).query((data: interfaces.master.VesselMaster) => {

                    this.vesselMaster = data;

                    this.breadCrumbDesc = this.vesselMaster.VesselCallSign + " - " + this.vesselMaster.VesselName;

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.gvwVesselCustomsOperatorCode.data = this.vesselMaster.VesselCustomsOperatorCodeList;
                        this.isLoadingDetail = false;
                    });


                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            registerVesselCustomsOperatorCodeApi(gridApi: uiGrid.IGridApi) {
                this.apiVesselCustomsOperatorCode = gridApi;
            }

            loadShippingLinesSMDList(searchText: string) {
                return this.vesselService.getShippingLinesSMDDropDown(searchText,100).query({
                }, (resultList) => {
                }).$promise;
            }

            checkBoxVesselCustomsOperatorCodeChanged(entity) {
                if (this.apiVesselCustomsOperatorCode) {
                    this.apiVesselCustomsOperatorCode.rowEdit.setRowsDirty([entity]);
                }
            }

            customsOperatorCodeChanged(entity) {
                if (this.apiVesselCustomsOperatorCode) {
                    this.apiVesselCustomsOperatorCode.rowEdit.setRowsDirty([entity]);
                }
            }

            insertInlineVesselCustomsOperatorCode() {
                this.vesselMaster.VesselCustomsOperatorCodeList.push(<interfaces.master.VesselCustomsOperatorCode>{
                    Id: 0,
                    IsActive: true
                });
            }

            deleteVesselCustomsOperatorCode() {
                if (this.apiVesselCustomsOperatorCode && this.apiVesselCustomsOperatorCode.selection.getSelectedRows().length > 0) {
                    var selected = this.apiVesselCustomsOperatorCode.selection.getSelectedRows()[0];
                    if (selected.Id > 0) {
                        selected.IsActive = false;
                        if (this.apiVesselCustomsOperatorCode) {
                            this.apiVesselCustomsOperatorCode.rowEdit.setRowsDirty([selected]);
                        }
                    }
                    else {
                        selected.IsActive = false;
                        _.remove(this.gvwVesselCustomsOperatorCode.data, (o) => {
                            return o === selected;
                        });
                    }
                } else {
                    this.generalService.displayMessage("Please select a Vessel Customs Operator Code record.", Enum.EnumMessageType.Warning);
                }
            }            

            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }


            gvwVesselCustomsOperatorCode: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                enableSorting: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                multiSelect: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => {
                    this.registerVesselCustomsOperatorCodeApi(gridApi);
                    this.apiVesselCustomsOperatorCode.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiVesselCustomsOperatorCode.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        cellTemplate: `
                        <div class="GridButton"> 
                            <gts-stamp-info ng-disabled="row.entity.Id < 1" createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate" updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate"></gts-stamp-info>
                        </div>`,
                        width: 40,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.vesselUpdateCtrl.insertInlineVesselCustomsOperatorCode()" ng-disabled="(grid.appScope.vesselUpdateCtrl.vesselMaster.LockdownType >= 2)">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: "Country",
                        displayName: "* Country",
                        field: "Country",
                        /*cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },*/
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.vesselUpdateCtrl.loadCountries()" required="true"></gts-dropdown>
                                </form>`,
                    },
                    {
                        name: "OperatorCode",
                        displayName: "* Operator Code",
                        field: "OperatorCode",
                        /*cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },*/
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="text" maxlength="70" name="BankName-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor />
                                </p>
                            </form>`,
                    },
                    {
                        name: "OperatorName",
                        displayName: "* Operator Name",
                        field: "OperatorName",
                        /*cellEditableCondition: function (scope) {
                            return scope.row.entity.Id < 1
                        },*/
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="text" maxlength="70" name="BankName-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor />
                                </p>
                            </form>`,
                    },
                    {
                        name: "IsActive",
                        displayName: "Active",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        enableFiltering: false,
                        cellTemplate: `<input type="checkbox" ng-model="row.entity.IsActive" ng-change="grid.appScope.vesselUpdateCtrl.checkBoxVesselCustomsOperatorCodeChanged(row.entity)" ng-disabled="grid.appScope.vesselUpdateCtrl.vesselMaster.LockdownType >= 2">`,
                        editableCellTemplate: `<form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-model="row.entity.IsActive" ng-disabled="grid.appScope.vesselUpdateCtrl.vesselMaster.LockdownType >= 2">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            }

            SaveVessel(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();


                if (this.apiVesselCustomsOperatorCode) {
                    this.vesselMaster.VesselCustomsOperatorCodeList = _.map(this.apiVesselCustomsOperatorCode.rowEdit.getDirtyRows(this.apiVesselCustomsOperatorCode.grid), (o) => { return o.entity });
                }
                else {
                    this.vesselMaster.VesselCustomsOperatorCodeList = [];
                }

                this.vesselService.save().save(this.vesselMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
   
                    if (this.apiVesselCustomsOperatorCode) {
                        this.apiVesselCustomsOperatorCode.rowEdit.setRowsClean(this.vesselMaster.VesselCustomsOperatorCodeList);
                    }

                    this.vesId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ves.update'
                },
                    () => {
                        return this.SaveVessel().then((data: boolean) => {
                            if (data) {
                                this.loadVessel(this.vesId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.Vessel.Update", { comId: this.vesId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'ves.update'
                },
                    () => {
                        return this.SaveVessel().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }


        }

         angular.module("app").controller("vesselUpdateCtrl", controllers.master.vesselUpdateCtrl);    
    }
}